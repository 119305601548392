import {
	mapState,
	mapActions
} from 'vuex';
import Vue from 'vue';
import {
	Empty
} from 'vant';
import {
	Popup
} from 'vant';
import {
	Field
} from 'vant';
import {
	Toast
} from 'vant';
import {
	Dialog
} from 'vant';

Vue.use(Popup);
Vue.use(Empty);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);

export default {
	name: 'setup',
	components: {},
	data() {
		return {
			show: false,
			nickName: '',
			TnickName: '',
			userPhone: '',
			TuserPhone: '',
			userAvar: require("../../assets/image/mo.png"),


		};
	},
	created() {
		this.TnickName = localStorage.getItem('userName');
		this.nickName = localStorage.getItem('userName');
		this.TuserPhone = localStorage.getItem('userPhone');
		this.userPhone = localStorage.getItem('userPhone');
		if (localStorage.getItem('userImage') != '' && localStorage.getItem('userImage') != null) {
			this.userAvar = localStorage.getItem('userImage')
		}
		// this.myInfo().then(res=>{
		//   if(res.code==200){
		//     // 昵称
		//     var user=res.user
		//     this.TnickName=user.userName;
		//     this.nickName=user.userName;
		//     // var startstr=user.userName.substr(0, user.userName.length-1);
		//     // this.nickName=startstr+user.userName.substring(user.userName.length-1).replace(/[\s\S]/ig, '*');
		//     //手机号
		//     this.TuserPhone=user.userPhone;
		//     this.userPhone=user.userPhone;
		//     // var startstr1=user.userPhone.substr(0, 3);
		//     // var endstr1=user.userPhone.substr(user.userPhone.length-4, user.userPhone.length-1);
		//     // this.userPhone=startstr1+user.userPhone.substring(4,user.userPhone.length-3).replace(/[\s\S]/ig, '*')+endstr1;
		//     if(user.userImage !='' &&user.userImage !=null){
		//       this.userAvar=user.userImage
		//     }
		//   }else{
		//     Toast(res.msg)

		//   }
		// })

	},
	methods: {
		...mapActions('setup', ['uploadAvar', 'myInfo', 'loginOut', 'editNick']),
		toedit() {
			this.show = true

		},
		back() {
			this.$router.go(-1);
		},
		isClose() {
			this.show = false
		},
		isOk() {
			var self = this

			if (this.TnickName.length < 2 || this.TnickName.length > 15) {
				Toast({
					message: "昵称长度应为2-15位！",
					className: "toas"
				});
			} else {
				this.editNick({
					NickName: this.TnickName
				}).then(res => {
					if (res.code == 200) {
						Toast({
							message: '修改成功！',
							className: "toas",
							onClose: function() {
								self.show = false;
								self.nickName = self.TnickName

							}
						});
					} else {
						Toast({
							message: res.msg,
							className: "toas"
						});
					}

				})


			}

		},
		tofwd() {
			this.$router.push({
				name: 'FindPwd',
				query: {
					types: 1
				}
			});
		},
		upload: function(event) {
			var that = this;
			var file = event.currentTarget.files[0];
			const tempFilesSize = file.size
			if (tempFilesSize <= 10000000) {
				Toast.loading({
					message: '上传中...',
					forbidClick: true,
					loadingType: 'spinner',
					duration: 0,
				});
				var formData = new FormData();
				formData.append("image", file);

				this.uploadAvar(
					formData
				).then(res => {
					Toast(res.msg)
					if (res.code == '200') {
						this.userAvar = res.imgUrl
						Toast.clear()
						Toast({
							message: '上传成功!',
							duration: 1000
						})

					} else {
						Toast.clear()
						Toast(res.msg)
					}


				})

			} else { //图片大于2M，弹出一个提示框
				Toast('上传图片不能大于10M!')
			}

		},
		cloginOut() {
			var self = this
			Dialog.confirm({
					title: '系统提示',
					message: '确认退出登录!',
					confirmButtonText: '确定',
					confirmButtonColor: "#5d6d8e",
					showCancelButton: true,
					className: 'tishi'
				}).then(() => {
					//  localStorage.setItem('code', code)
					self.loginOut().then(res => {
						if (res.code == 200) {
							localStorage.setItem('token', '');
							self.$router.push({
								name: 'Home'
							})
						} else {
							Toast({
								message: res.msg,
							});
						}
					})

				})
				.catch(() => {
					// on cancel
				});


		},




	},
};
